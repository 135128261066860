.bootstrap-multiemail {
	list-style: none;
    padding: 6px 6px 50px;
    border: 1px solid #f1f1f1;
    background-color: #FFF;
    border-radius: 1px;
    cursor: text;
    position: relative;
    min-height: 300px;
    overflow: auto;
    width: 100%;
}
.bootstrap-multiemail input {
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    margin: 0;
    width: auto;
    padding: 0;
    height: auto;
    line-height: 28px;
    min-width: 255px;
}


.bootstrap-multiemail .tag {
	border-radius: 3px;
    padding: 2px 8px;
    margin-right: 6px;
    margin-bottom: 6px;
    float: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	background-color: #fff;
	border: 1px solid #019be2;
	border-radius: 3px;
	color: #18b5fe;
	font-weight:600;
}

.bootstrap-multiemail .tag.invalid {
    background-color: #fff;
    border: 1px solid #ac2925;
    color: #d9534f;
}