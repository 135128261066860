/* iCheck plugin Line skin, green
----------------------------------- */
.icheckbox_line-green,
.iradio_line-green {
    position: relative;
    display: block;
    margin: 0;
    padding: 5px 15px 5px 38px;
    font-size: 13px;
    line-height: 17px;
    color: #fff;
    background: #1b7e5a;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    cursor: pointer;
}
    .icheckbox_line-green .icheck_line-icon,
    .iradio_line-green .icheck_line-icon {
        position: absolute;
        top: 50%;
        left: 13px;
        width: 13px;
        height: 11px;
        margin: -5px 0 0 0;
        padding: 0;
        overflow: hidden;
        background: url(line.png) no-repeat;
        border: none;
    }
    .icheckbox_line-green.hover,
    .icheckbox_line-green.checked.hover,
    .iradio_line-green.hover {
        background: #24AA7A;
    }
    .icheckbox_line-green.checked,
    .iradio_line-green.checked {
        background: #1b7e5a;
    }
        .icheckbox_line-green.checked .icheck_line-icon,
        .iradio_line-green.checked .icheck_line-icon {
            background-position: -15px 0;
        }
    .icheckbox_line-green.disabled,
    .iradio_line-green.disabled {
        background: #89E6C4;
        cursor: default;
    }
        .icheckbox_line-green.disabled .icheck_line-icon,
        .iradio_line-green.disabled .icheck_line-icon {
            background-position: -30px 0;
        }
    .icheckbox_line-green.checked.disabled,
    .iradio_line-green.checked.disabled {
        background: #89E6C4;
    }
        .icheckbox_line-green.checked.disabled .icheck_line-icon,
        .iradio_line-green.checked.disabled .icheck_line-icon {
            background-position: -45px 0;
        }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_line-green .icheck_line-icon,
    .iradio_line-green .icheck_line-icon {
        background-image: url(line@2x.png);
        -webkit-background-size: 60px 13px;
        background-size: 60px 13px;
    }
}